import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { idLocale } from 'ngx-bootstrap/chronos';
@Injectable({
  providedIn: 'root',
})
export class BillSettingsService {
  constructor(private http: HttpClient, private router: Router) { }

  getBillsSetingUrl(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    id: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    /*  let url;
     if (searchTerm) {
       url = `${AppConfigData.getBillSettingsUrl + id}?PageIndex=${pageIndex}&PageSize=${pageSize}&search=${searchTerm}`;
     } else {
       url = `${AppConfigData.getBillSettingsUrl + id}?&PageIndex=${pageIndex}&PageSize=${pageSize}`;
     } */

    let url =
      `${AppConfigData.getBillSettingsUrl + id}?pageIndex=${pageIndex}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getOptionalBillChargeUrl(id: string): Observable<any> {
    let url;
    url = `${AppConfigData.getOptionalBillChargeUrl + id}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getInvoiceUrl(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    orderBy?: string,
    filter?: string
  ): Observable<any> {
    let url;
    /*  if (searchTerm) {
       url = `${AppConfigData.getInviceeUrl}?ProjectId=${id}&PageIndex=${pageIndex}&PageSize=${pageSize}&search=${searchTerm}`;
     } else {
       url = `${AppConfigData.getInviceeUrl}?ProjectId=${id}&PageIndex=${pageIndex}&PageSize=${pageSize}`;
     } */

    url =
      `${AppConfigData.getInviceUrl}?PageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createBillCharge(reson: any): Observable<any> {
    return this.http.post<any>(AppConfigData.createBillChargeUrl, reson).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updatetBillCharge(billChatrge: any, id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updateBillChargeUrl + id, billChatrge)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getMasters failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  createoptionalBillChargeUrl(bills: any): Observable<any> {
    //console.log('userData->', bills);

    return this.http
      .post<any>(AppConfigData.createoptionalBillChargeUrl, bills)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  updateoptionalBillChargeUrl(billChatrge: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updateoptionalBillChargeUrl, billChatrge)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getMasters failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
  createInvoiceAddUrl(invoice: any, id: string): Observable<any> {
    console.log('userData->', invoice);
    console.log('userData->', id);
    const formData = new FormData();
    let fromdate = invoice.date[0];
    let todate = invoice.date[1];
    invoice.slot && formData.append('Slot', invoice.slot);
    formData.append('ProjectId', id);
    formData.append('From', fromdate.toLocaleString());
    formData.append('To', todate.toLocaleString());
    invoice.files && formData.append('File', invoice.files[0].rawFile);
    return this.http.post<any>(AppConfigData.createinvoiceAdUrl, formData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updateinvoiceAddUrl(
    invoice: any,
    id: string,
    projectId: string
  ): Observable<any> {
    const formData = new FormData();
   // Assuming invoice.date[0] and invoice.date[1] are Date objects
let fromdate = new Date(invoice.date[0].getTime() - invoice.date[0].getTimezoneOffset() * 60000);
let todate = new Date(invoice.date[1].getTime() - invoice.date[1].getTimezoneOffset() * 60000);

    invoice.slot && formData.append('Slot', invoice.slot);
    formData.append('ProjectId', projectId);
    formData.append('From', fromdate.toISOString().slice(0, 10), );
    formData.append('To', todate.toISOString().slice(0, 10), );
    invoice.files && formData.append('File', invoice.files[0].rawFile);
    return this.http
      .post<any>(AppConfigData.updateinvoiceAdUrl + id, formData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getMasters failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteBillCharge(id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.deleteBillChargeUrl + id, null)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('deleteFaqs failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteInvoicead(id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.deleteInvoiceAdUrl + id, null)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('deleteFaqs failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
}
