import { createReducer, on } from '@ngrx/store';
import {
  mappingUmdSuccess,
  getListOfUnMappingUmdSuccess,
} from './mapping.actions';
import {
  getAllmappingSuccess,
  resetmappingDetailsState,
  resetState,
  unMappingUmdSuccess,
} from './mapping.actions';
import {
  Getmapping,
  getmappingFailure,
  getmappingSuccess,
} from './mapping.actions';
export interface MappingState {
  master: any | null;
  getmappingConfigResponse: any;
  getAllmappingConfigResponse: any;
  unMappingUmdSuccessResponse: any;
  mappingUmdSuccessResponse: any;
  getListOfUnMappingUmdResponse: any;
}

const initialState: MappingState = {
  master: null,
  getmappingConfigResponse: null,
  getAllmappingConfigResponse: null,
  unMappingUmdSuccessResponse: null,
  mappingUmdSuccessResponse: null,
  getListOfUnMappingUmdResponse: null,
};

export const mappingsReducer = createReducer(
  initialState,
  on(Getmapping, (state) => ({ ...state })),
  // on(GetmappingConsumptionHistory, (state) => ({ ...state })),
  on(getmappingSuccess, (state, { getmappingConfigResponse }) => ({
    ...state,
    getmappingConfigResponse,
  })),
  on(getmappingFailure, (state, { getmappingError }) => ({
    ...state,
    getmappingError,
  })),

  on(getAllmappingSuccess, (state, { getAllmappingConfigResponse }) => ({
    ...state,
    getAllmappingConfigResponse,
  })),

  on(unMappingUmdSuccess, (state, { unMappingUmdSuccessResponse }) => ({
    ...state,
    unMappingUmdSuccessResponse,
  })),

  on(mappingUmdSuccess, (state, { mappingUmdSuccessResponse }) => ({
    ...state,
    mappingUmdSuccessResponse,
  })),
  on(
    getListOfUnMappingUmdSuccess,
    (state, { getListOfUnMappingUmdResponse }) => ({
      ...state,
      getListOfUnMappingUmdResponse,
    })
  ),

  on(resetState, (state) => ({
    ...state,
    getAllmappingConfigResponse: null, // Resetting this specific property
    mappingUmdSuccessResponse:null,
    getListOfUnMappingUmdResponse:null,
    getmappingConfigResponse:null,
    unMappingUmdSuccessResponse:null,

  }))
);
