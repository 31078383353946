import { createReducer, on } from '@ngrx/store';
import { getElectricityRateDataSuccess, getEvDataSuccess, resetUnitRateHistoryState, resetUtilitiesState } from './utilities.actions';
import {
  getAllGasRateDataSuccess,
  getWaterRateDataSuccess,
} from './utilities.actions';
import {
  getutilitiesuccess,
  getutilitiesFailure,
  getGasRateDataSuccess,
} from './utilities.actions';
import {
  Createutilitiesuccess,
  createutilities,
  CreateutilitiesFailure,
} from './utilities.actions';
import { resetInvoiceState } from '../billsSettings/billSettings.action';

export interface UtilitiesState {
  createutilitiesResponse1: any;
  createutilitiesError: any;
  getutilitiesResponse: any;
  getGasRateDataResponse: any;
  getAllGasRateDataResponse: any;
  getWaterRateDataResponse: any;
  getElectricityRateDataResponse: any;
  getEvDataResponse:any;
}

const initialState: UtilitiesState = {
  createutilitiesResponse1: null,
  createutilitiesError: null,
  getutilitiesResponse: null,
  getGasRateDataResponse: null,
  getAllGasRateDataResponse: null,
  getWaterRateDataResponse: null,
  getElectricityRateDataResponse: null,
  getEvDataResponse:null
};

export const UtilitiesReducer = createReducer(
  initialState,
  on(createutilities, (state) => ({ ...state, createutilitiesError: null })),
  on(Createutilitiesuccess, (state, { createutilitiesResponse1 }) => ({
    ...state,
    createutilitiesResponse1,
  })),
  on(CreateutilitiesFailure, (state, { createutilitiesError }) => ({
    ...state,
    createutilitiesError,
  })),
  on(getutilitiesuccess, (state, { getutilitiesResponse }) => ({
    ...state,
    getutilitiesResponse,
  })),
  on(getutilitiesFailure, (state, { getutilitiesError }) => ({
    ...state,
    getutilitiesError,
  })),
  on(getGasRateDataSuccess, (state, { getGasRateDataResponse }) => ({
    ...state,
    getGasRateDataResponse,
  })),
  on(getAllGasRateDataSuccess, (state, { getAllGasRateDataResponse }) => ({
    ...state,
    getAllGasRateDataResponse,
  })),
  on(getWaterRateDataSuccess, (state, { getWaterRateDataResponse }) => ({
    ...state,
    getWaterRateDataResponse,
  })),
  on(
    getElectricityRateDataSuccess,
    (state, { getElectricityRateDataResponse }) => ({
      ...state,
      getElectricityRateDataResponse,
    })
  ) , 
  on(
    getEvDataSuccess,
    (state, { getEvDataResponse }) => ({
      ...state,
      getEvDataResponse,
    })
  ) , 
  on(resetUnitRateHistoryState, (state) => ({
    ...state,
    getAllGasRateDataResponse: null,
    // Resetting this specific property
  }))
  ,
  on(resetUtilitiesState, (state) => ({
    ...state,
    getutilitiesResponse: null,
    // Resetting this specific property
  }))
);
