import { createReducer, on } from '@ngrx/store';
import { meter } from './meters.models';
import {
  getAllmeterConsumptionSuccess,
  checkMeterStatusSuccess,
  GetAllMeterDetails,
  getAllmeterSuccess,
  resetMeterDetailsState,
  resetState,
  updateMeterStatusFailure,
  updateMeterStatusSuccess,
  getMeterDetails,
  meterRedaingExportReset,
  getAdminmeteronOffHistory,
  geAdminmeteronOffHistorySuccess,
  geAdminmeteronOffHistoryFailure,
  getAdminmeteronOffHistoryExport,
  geAdminmeteronOffHistoryExportSuccess,
  geAdminmeteronOffHistoryExportFailure,
} from './meters.actions';
import {
  Getmeter,
  getmeterFailure,
  getmeterSuccess,
  getmeterConsumptionSuccess,
  getmeterConsumptionFailure,
} from './meters.actions';
export interface MetersState {
  master: meter | null;
  getmeterConfigResponse: any;
  getmeterConsumptionResponse: any;
  getmeterError: any;
  updateMeterStatusError: any;
  updateMeterStatusResponse: any;
  getAllmeterConfigResponse: any;
  getAllmeterConsumptionResponse: any;
  checkMeterStatusResponse: any;
  getAdminmeteronOffHistoryResponse: any,
  getAdminmeteronOffHistoryError: any,
  getAdminmeteronOffHistoryExportResponse: any
}

const initialState: MetersState = {
  master: null,
  getmeterConfigResponse: null,
  getmeterConsumptionResponse: null,
  getmeterError: null,
  updateMeterStatusError: null,
  updateMeterStatusResponse: null,
  getAllmeterConfigResponse: null,
  getAllmeterConsumptionResponse: null,
  checkMeterStatusResponse: null,
  getAdminmeteronOffHistoryResponse: null,
  getAdminmeteronOffHistoryError: null,
  getAdminmeteronOffHistoryExportResponse: null
};

export const metersReducer = createReducer(
  initialState,
  on(Getmeter, (state) => ({ ...state })),
  on(getmeterSuccess, (state, { getmeterConfigResponse }) => ({
    ...state,
    getmeterConfigResponse,
  })),
  on(getmeterFailure, (state, { getmeterError }) => ({
    ...state,
    getmeterError,
  })),


  on(getAdminmeteronOffHistory, (state) => ({ ...state })),
  on(geAdminmeteronOffHistorySuccess, (state, { getAdminmeteronOffHistoryResponse }) => ({
    ...state,
    getAdminmeteronOffHistoryResponse,
  })),
  on(geAdminmeteronOffHistoryFailure, (state, { getAdminmeteronOffHistoryError }) => ({
    ...state,
    getAdminmeteronOffHistoryError,
  })),

  on(getAdminmeteronOffHistoryExport, (state) => ({ ...state })),
  on(geAdminmeteronOffHistoryExportSuccess, (state, { getAdminmeteronOffHistoryExportResponse }) => ({
    ...state,
    getAdminmeteronOffHistoryExportResponse,
  })),
  on(geAdminmeteronOffHistoryExportFailure, (state, { getAdminmeteronOffHistoryExportError }) => ({
    ...state,
    getAdminmeteronOffHistoryExportError,
  })),






  on(getMeterDetails, (state) => ({ ...state })),
  on(getmeterConsumptionSuccess, (state, { getmeterConsumptionResponse }) => ({
    ...state,
    getmeterConsumptionResponse,
  })),
  on(getmeterConsumptionFailure, (state, { getmeterConsumptionError }) => ({
    ...state,
    getmeterConsumptionError,
  })),
  on(updateMeterStatusSuccess, (state, { updateMeterStatusResponse }) => ({
    ...state,
    updateMeterStatusResponse,
  })),
  on(updateMeterStatusFailure, (state, { updateMeterStatusError }) => ({
    ...state,
    updateMeterStatusError,
  })),
  on(getAllmeterSuccess, (state, { getAllmeterConfigResponse }) => ({
    ...state,
    getAllmeterConfigResponse,
  })),
  on(
    getAllmeterConsumptionSuccess,
    (state, { getAllmeterConsumptionResponse }) => ({
      ...state,
      getAllmeterConsumptionResponse,
    })
  ),
  on(resetMeterDetailsState, (state) => ({
    ...state,
    getAllmeterConsumptionResponse: null,
    getmeterConsumptionResponse:null,
    getAdminmeteronOffHistoryResponse:null


    // Resetting this specific property
  })),
  on(
    checkMeterStatusSuccess,
    (state, { checkMeterStatusResponse }) => ({
      ...state,
      checkMeterStatusResponse,
    })
  ),

  /*  on(meterRedaingExportReset, (state) => ({
     ...state,
     getAllmeterConsumptionResponse: null, // Resetting this specific property
   })), */
  on(resetState, (state) => ({
    ...state,
    getAllmeterConfigResponse: null, // Resetting this specific property
  }))
);
