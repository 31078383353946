import {
    getBillslist, getBilsSuccess, getBillsFailure,
    getManuallist, getManualSuccess, getManualFailure,
    getAdhoclist, getAdhocSuccess, getAdhocFailure, getBillsByIdlist, getBillsByIdSuccess, getBillsByIdFailure,
    createinvildBills,
    addinvildBillsSuccess,
    addinvildBillsFailure, createBills, addBillsSuccess, addBillsFailure,
    getResidentiallist, getResidentialSuccess, getresidentialFailure,
    getBillReadingsInfoList,
    getBillReadingsInfoFailure,
    getBillReadingsInfoSuccess,
    getBillsListExport,
    getBilsExportSuccess,
    getBillsExportFailure,
    getManualExportSuccess,
    getManualExportFailure,
    getManuallistExport,
    getAdhocExpoertSuccess,
    getAdhocExpoertFailure,
    getAdhoclistExport,
    getBillReadingsByMonthSuccess,
    getBillReadingsByMonthFailure,
    getBillReadingsByMonthList,
    getBillReadingsByMonthAdhocFailure,
    getBillReadingsByMonthAdhocSuccess,
    getBillReadingsByMonthAdhocList,
    PaycashbillSuccess,
    PaycashbillFailure,
    Paycashbill,
    getBillsInvoice,
    getBillsInvoiceFailure,
    getBillsInvoiceSuccess,
    getBillsInvoiceExportSuccess,
    getBillsInvoiceExportFailure,
    getBillsInvoiceExport
} from "./bills.action";


import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { BillsService } from "../../core/services/bills.service";

@Injectable()
export class billsEffects {
    ServiceError = Validations.ServiceError;



    createBills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createBills),
            exhaustMap((bills) => {
                console.log(bills);
                this.loaderService.setLoading(true);
                return this.BillsService.createBill(bills.bilss).pipe(
                    map((response: any) => {
                        console.log(response);
                        this.loaderService.setLoading(false);
                        if (response && response.statusCode === 201) {
                            this.sharedService.showMessageDialog('Success', response.message, SweetAlertIcon.SUCCESS);
                            this.router.navigate(['/bills']);
                            return addBillsSuccess({ createBillsResponse: response })
                        } else {
                            this.sharedService.showMessageDialog('Failure', response.message, SweetAlertIcon.ERROR);
                            return addBillsFailure({ createBillsError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);

                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(addBillsFailure({ createBillsError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );

    Paycashbill$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Paycashbill),
            exhaustMap((bills) => {
                console.log(bills);
                this.loaderService.setLoading(true);
                return this.BillsService.paycashbill(bills.bilss).pipe(
                    map((response: any) => {
                        console.log(response);
                        this.loaderService.setLoading(false);
                        if (response && response.statusCode === 201) {
                            this.sharedService.showMessageDialog('Success', response.message, SweetAlertIcon.SUCCESS);
                            // this.router.navigate(['/bills']);
                            return PaycashbillSuccess({ PaycashbillSuccessResponse: response })
                        } else {
                            this.sharedService.showMessageDialog('Failure', response.message, SweetAlertIcon.ERROR);
                            return PaycashbillFailure({ PaycashbillFailureError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);

                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(PaycashbillFailure({ PaycashbillFailureError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );



    getResidentiallist$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getResidentiallist),
            exhaustMap(({ projectId }) => {
                this.loaderService.setLoading(true);
                return this.BillsService.getResidentialUrl(projectId).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getResidentialSuccess({ getresidentialResponse: response.data });
                        } else {
                            return getresidentialFailure({ getResidentialError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getresidentialFailure({ getResidentialError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );

    getBillReadingsInfoList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillReadingsInfoList),
            exhaustMap(({ id, projectId, MacAddress }) => {
                this.loaderService.setLoading(true);
                return this.BillsService.getBillReadingsInfoUrl(id, projectId, MacAddress).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getBillReadingsInfoSuccess({ getBillReadingsInfoResponse: response.data });
                        } else {
                            return getBillReadingsInfoFailure({ getBillReadingsInfoError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getBillReadingsInfoFailure({ getBillReadingsInfoError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );

    getBillReadingsByMonthList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillReadingsByMonthList),
            exhaustMap(({ id, projectId, BillMonth, MacAddress, IsCombined }) => {
                this.loaderService.setLoading(true);
                return this.BillsService.getBillDeatilsByMonthUrl(id, projectId, BillMonth, MacAddress, IsCombined).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getBillReadingsByMonthSuccess({ getBillReadingsByMonthResponse: response.data });
                        } else {
                            return getBillReadingsByMonthFailure({ getBillReadingsByMonthError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getBillReadingsByMonthFailure({ getBillReadingsByMonthError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );

    getBillReadingsByMonthAdhocList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillReadingsByMonthAdhocList),
            exhaustMap(({ adhocBills }) => {
                this.loaderService.setLoading(true);
                return this.BillsService.getAdhocBillDeatilsByMonthUrl(adhocBills).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getBillReadingsByMonthAdhocSuccess({ getBillReadingsByMonthAdhocResponse: response.data });
                        } else {
                            return getBillReadingsByMonthAdhocFailure({ getBillReadingsByMonthAdhocError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getBillReadingsByMonthAdhocFailure({ getBillReadingsByMonthAdhocError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );



    getBillslist$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillslist),
            exhaustMap(({ pageIndex, pageSize, searchTerm, projectId, billType, filter,
                orderBy, }) => {
                if (!searchTerm) {
                    this.loaderService.setLoading(true);
                }
                return this.BillsService.getBillsUrl(pageIndex, pageSize, searchTerm, projectId, billType, filter,
                    orderBy,).pipe(
                        map((response: any) => {
                            this.loaderService.setLoading(false);
                            console.log(response);
                            if (response && response.statusCode === 200) {
                                return getBilsSuccess({ getBillsResponse: response.data });
                            } else {
                                return getBillsFailure({ getBillsError: response });
                            }
                        }),
                        catchError((error) => {
                            console.log(error);
                            this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                            return of(getBillsFailure({ getBillsError: error }));
                        }), finalize(() => this.loaderService.setLoading(false))
                    );
            })
        )
    );


    getBillsListExport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillsListExport),
            exhaustMap(({ pageIndex, pageSize, searchTerm, projectId, billType, filter,
                orderBy, }) => {
                if (!searchTerm) {
                    this.loaderService.setLoading(true);
                }
                return this.BillsService.getBillsUrl(pageIndex, pageSize, searchTerm, projectId, billType, filter,
                    orderBy,).pipe(
                        map((response: any) => {
                            this.loaderService.setLoading(false);
                            console.log(response);
                            if (response && response.statusCode === 200) {
                                return getBilsExportSuccess({ getBillsExportResponse: response.data });
                            } else {
                                return getBillsExportFailure({ getBillsExportError: response });
                            }
                        }),
                        catchError((error) => {
                            console.log(error);
                            this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                            return of(getBillsExportFailure({ getBillsExportError: error }));
                        }), finalize(() => this.loaderService.setLoading(false))
                    );
            })
        )
    );

    getManuallist$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getManuallist),
            exhaustMap(({ pageIndex, pageSize, searchTerm, projectId, billType, filter,
                orderBy, }) => {
                if (!searchTerm) {
                    this.loaderService.setLoading(true);
                }
                return this.BillsService.getManualUrl(pageIndex, pageSize, searchTerm, projectId, billType, filter,
                    orderBy,).pipe(
                        map((response: any) => {
                            this.loaderService.setLoading(false);
                            console.log(response);
                            if (response && response.statusCode === 200) {
                                return getManualSuccess({ getManualResponse: response.data });
                            } else {
                                return getManualFailure({ getManualError: response });
                            }
                        }),
                        catchError((error) => {
                            console.log(error);
                            this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                            return of(getManualFailure({ getManualError: error }));
                        }), finalize(() => this.loaderService.setLoading(false))
                    );
            })
        )
    );
    getManuallistExport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getManuallistExport),
            exhaustMap(({ pageIndex, pageSize, searchTerm, projectId, billType, filter,
                orderBy, }) => {
                if (!searchTerm) {
                    this.loaderService.setLoading(true);
                }
                return this.BillsService.getManualUrl(pageIndex, pageSize, searchTerm, projectId, billType, filter,
                    orderBy,).pipe(
                        map((response: any) => {
                            this.loaderService.setLoading(false);
                            console.log(response);
                            if (response && response.statusCode === 200) {
                                return getManualExportSuccess({ getManualExportResponse: response.data });
                            } else {
                                return getManualExportFailure({ getManualExportError: response });
                            }
                        }),
                        catchError((error) => {
                            console.log(error);
                            this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                            return of(getManualExportFailure({ getManualExportError: error }));
                        }), finalize(() => this.loaderService.setLoading(false))
                    );
            })
        )
    );

    getAdhoclist$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAdhoclist),
            exhaustMap(({ pageIndex, pageSize, searchTerm, projectId, billType, filter,
                orderBy, }) => {
                if (!searchTerm) {
                    this.loaderService.setLoading(true);
                }
                return this.BillsService.getAdhocBillsUrl(pageIndex, pageSize, searchTerm, projectId, billType).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getAdhocSuccess({ getAdhoctResponse: response.data });
                        } else {
                            return getAdhocFailure({ getAdhocError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getAdhocFailure({ getAdhocError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );
    getAdhoclistExport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAdhoclistExport),
            exhaustMap(({ pageIndex, pageSize, searchTerm, projectId, billType, filter,
                orderBy, }) => {
                if (!searchTerm) {
                    this.loaderService.setLoading(true);
                }
                return this.BillsService.getAdhocBillsUrl(pageIndex, pageSize, searchTerm, projectId, billType).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getAdhocExpoertSuccess({ getAdhoctExportResponse: response.data });
                        } else {
                            return getAdhocExpoertFailure({ getAdhocExportError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getAdhocExpoertFailure({ getAdhocExportError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );

    getBillsByIdlist$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillsByIdlist),
            exhaustMap(({ id }) => {
                this.loaderService.setLoading(true);
                return this.BillsService.getBillsById(id).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getBillsByIdSuccess({ getBillsByIdResponse: response.data });
                        } else {
                            return getBillsByIdFailure({ getBillsByIdError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getBillsByIdFailure({ getBillsByIdError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );


    createinvildBills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createinvildBills),
            exhaustMap((payload) => {

                this.loaderService.setLoading(true);
                return this.BillsService.creatiInvalidBills(payload.payload).pipe(
                    map((response: any) => {
                        console.log(response);
                        this.loaderService.setLoading(false);
                        if (response && response.statusCode === 201) {
                            this.sharedService.showMessageDialog('Success', response.message, SweetAlertIcon.SUCCESS);
                            return addinvildBillsSuccess({ createinvildBillsResponse: response })
                        } else {
                            this.sharedService.showMessageDialog('Failure', response.message, SweetAlertIcon.ERROR);
                            return addinvildBillsFailure({ createinvildBillsError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);

                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(addinvildBillsFailure({ createinvildBillsError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );

    getBillsInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillsInvoice),
            exhaustMap(({
                ProjectId,
                pageIndex,
                pageSize,
                searchTerm,
                filter,
                orderBy,
                IsConsumer
            }) => {
                this.loaderService.setLoading(true);
                return this.BillsService.getbillsInvoice(ProjectId,
                    pageIndex,
                    pageSize,
                    searchTerm,
                    filter,
                    orderBy,
                    IsConsumer
                ).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getBillsInvoiceSuccess({
                                getBillsInvoiceResponse: response.data,
                            });
                        } else {
                            return getBillsInvoiceFailure({ getBillsInvoiceError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(
                            error.error.message,
                            this.ServiceError.Error,
                            SweetAlertIcon.ERROR
                        );
                        return of(getBillsInvoiceFailure({ getBillsInvoiceError: error }));
                    }),
                    finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );
    getBillsInvoiceExport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getBillsInvoiceExport),
            exhaustMap(({ ProjectId, criteria, IsConsumer }) => {
                this.loaderService.setLoading(true);
                return this.BillsService
                    .getInvoiceExport(ProjectId, criteria, IsConsumer)
                    .pipe(
                        map((response: any) => {
                            this.loaderService.setLoading(false);
                            console.log(response);
                            if (response && response.statusCode === 200) {
                                return getBillsInvoiceExportSuccess({
                                    getBillsInvoiceExportResponse: response.data,
                                });
                            } else {
                                return getBillsInvoiceExportFailure({
                                    getBillsInvoiceExportError: response,
                                });
                            }
                        }),
                        catchError((error) => {
                            console.log(error);
                            this.sharedService.showMessageDialog(
                                error.error.message,
                                this.ServiceError.Error,
                                SweetAlertIcon.ERROR
                            );
                            return of(
                                getBillsInvoiceExportFailure({ getBillsInvoiceExportError: error })
                            );
                        }),
                        finalize(() => this.loaderService.setLoading(false))
                    );
            })
        )
    );



    constructor(
        private actions$: Actions,
        private router: Router,
        private BillsService: BillsService,
        private loaderService: LoaderService,
        private sharedService: SharedService,
    ) { }


}