import { Action, createReducer, on } from '@ngrx/store';
import {
    createConsumer, addConsumerSuccess, addConsumerFailure,
    updateConsumer, updateConsumerSuccess, updateConsumerFailure,
    deleteConsumer, deleteConsumerSuccess, deleteConsumerFailure,
    getConsumer, getConsumerSuccess, getConsumerrFail,
    getConsumerRechargeHistory,
    getConsumerRechargeHistoryFail,
    getConsumerRechargeHistorySuccess,
    getConsumerFaq,
    getConsumerFaqSuccess,
    getConsumerFaqFail,
    getConsumptionHistory,
    getConsumptionHistorySuccess,
    getConsumptionHistoryFail,
    getConsumerExportrFail,
    getConsumerExport,
    getConsumerExportSuccess,
    getmeterDeatails,
    getmeterDeatailsSuccess,
    getmeterDeatailsFail,
    getmeterControls,
    getmeterControlsSuccess,
    getmeterControlsFail,
    getConsumerMeterExport,
    getConsumerMeterExportSuccess,
    getConsumerMeterExportrFail,
    getConsumerRechargeHistoryExport,
    getConsumerRechargeHistoryExportSuccess,
    getConsumerRechargeHistoryExportFail,
    getConsumptionHistoryExport,
    getConsumptionHistoryExportSuccess,
    getConsumptionHistoryExportFail,
    getDueBills,
    getDueBillsSuccess,
    getDueBillsFail,
    getBillHistory,
    getBillHistorySuccess,
    getBillHistoryFail,
    getBillHistoryExport,
    getBillHistoryExportSuccess,
    getBillHistoryExportFail,
    getBilldeatilsSuccess,
    getBilldeatilsFail,
    getBilldeatils,
    getConsumerMeteOnOffExport,
    getConsumerMeterOnOffExportSuccess,
    getConsumerMeterOnOffExportrFail,
    resetState,
    resetBillHistoryState,
    resetBillRechargeHistoryState,
    resetBillonOffControlsState,
    resetBillmeterControlsState,
    resetConsumptionHistoryState,
    resetrechargeHistoryState,
    updateConsumerMeterDetails,
    updateConsumerMeterDetailstSuccess,
    updateConsumerMeterDetailsFail,
    resetupdateConsumerMeterDetails,
    getMeterOnOffControls,
    getMeterOnOffControlsSuccess,
    getMeterOnOffControlsFail,
    getsettlement,
    getsettlementSuccess,
    getsettlementFail,
    getsettlementExportFail,
    getsettlementExportSuccess,
    getsettlementExport,
    resetsettlementExportState,
    retrysettlement,
    retrysettlementSuccess,
    retrysettlementFail,
    checkStatussettlement,
    checkStatusettlementSuccess,
    checkStatusettlementFail,
    getConsumersresetState,

} from './consumer.action';
import { CustomersModel } from './consumer.model';
import { AnyARecord } from 'dns';


export interface ConsumerState {
    consumer: CustomersModel | null;
    createConsumerError: any;
    getConsumersResponse: any;
    createConsumerResponse: any;
    deleteConsumerResponse: any
    getConsumersRechargeHistoryResponse: any,
    getConsumersFaqResponse: any,
    getconsumptionHistoryResponse: any,
    getConsumersExportResponse: any,
    getmeterDeatailsResponse: any,
    getmeterControlsResponse: any,
    getConsumersMeterExportResponse: any
    getConsumersRechargeHistoryExportResponse: any,
    getconsumptionHistoryExportResponse: any,
    getDueBillsResponse: any,
    getBillHistoryResponse: any,
    getBillHistoryExportResponse: any,
    getBilldeatilsResponse: any,
    getConsumersMeterOnOffExportResponse: any,
    updateConsumerMeterDetailssuccessResponse: any,
    getMeterOnOffControlsResponse: any,
    getsettlementResponse: any,
    getsettlementExportResponse: any,
    retrysettlementResponse: any;
    checkStatusettlementResponse: any

}

export const initialState: ConsumerState = {
    consumer: null,
    createConsumerError: null,
    getConsumersResponse: null,
    createConsumerResponse: null,
    deleteConsumerResponse: null,
    getConsumersRechargeHistoryResponse: null,
    getConsumersFaqResponse: null,
    getconsumptionHistoryResponse: null,
    getConsumersExportResponse: null,
    getmeterDeatailsResponse: null,
    getmeterControlsResponse: null,
    getConsumersMeterExportResponse: null,
    getConsumersRechargeHistoryExportResponse: null,
    getconsumptionHistoryExportResponse: null,
    getDueBillsResponse: null,
    getBillHistoryResponse: null,
    getBillHistoryExportResponse: null,
    getBilldeatilsResponse: null,
    getConsumersMeterOnOffExportResponse: null,
    updateConsumerMeterDetailssuccessResponse: null,
    getMeterOnOffControlsResponse: null,
    getsettlementResponse: null,
    getsettlementExportResponse: null,
    retrysettlementResponse: null,
    checkStatusettlementResponse: null
};

export const ConsumerReducer = createReducer(
    initialState,
    on(getConsumer, (state) => {
        return { ...state, loading: true, error: null };
    }),
    on(getConsumerSuccess, (state, { getConsumersResponse }) => ({ ...state, getConsumersResponse })),
    on(getConsumerrFail, (state, { getConsumersError }) => ({ ...state, getConsumersError })),


    on(getConsumerRechargeHistory, (state) => {
        return { ...state, loading: true, error: null };
    }),
    on(getConsumerRechargeHistorySuccess, (state, { getConsumersRechargeHistoryResponse }) => ({ ...state, getConsumersRechargeHistoryResponse })),
    on(getConsumerRechargeHistoryFail, (state, { getConsumersRechargeHistoryError }) => ({ ...state, getConsumersRechargeHistoryError })),


    on(getConsumerFaq, (state) => {
        return { ...state, loading: true, error: null };
    }),
    on(getConsumerFaqSuccess, (state, { getConsumersFaqResponse }) => ({ ...state, getConsumersFaqResponse })),
    on(getConsumerFaqFail, (state, { getConsumersFaqError }) => ({ ...state, getConsumersFaqError })),


    on(getConsumptionHistory, (state) => {
        return { ...state, loading: true, error: null };
    }),
    on(getConsumptionHistorySuccess, (state, { getconsumptionHistoryResponse }) => ({ ...state, getconsumptionHistoryResponse })),
    on(getConsumptionHistoryFail, (state, { getconsumptionHistoryError }) => ({ ...state, getconsumptionHistoryError })),

    on(createConsumer, state => ({ ...state, error: null })),
    on(addConsumerSuccess, (state, { createConsumerResponse }) => ({ ...state, createConsumerResponse })),
    on(addConsumerFailure, (state, { createConsumerError }) => ({ ...state, createConsumerError })),


    on(getConsumerExport, state => ({ ...state, error: null })),
    on(getConsumerExportSuccess, (state, { getConsumersExportResponse }) => ({ ...state, getConsumersExportResponse })),
    on(getConsumerExportrFail, (state, { getConsumersExportError }) => ({ ...state, getConsumersExportError })),


    on(getConsumerMeterExport, state => ({ ...state, error: null })),
    on(getConsumerMeterExportSuccess, (state, { getConsumersMeterExportResponse }) => ({ ...state, getConsumersMeterExportResponse })),
    on(getConsumerMeterExportrFail, (state, { getConsumersMeterExportError }) => ({ ...state, getConsumersMeterExportError })),


    on(checkStatussettlement, state => ({ ...state, error: null })),
    on(checkStatusettlementSuccess, (state, { checkStatusettlementResponse }) => ({ ...state, checkStatusettlementResponse })),
    on(checkStatusettlementFail, (state, { checkStatusettlementError }) => ({ ...state, checkStatusettlementError })),







    on(getConsumerRechargeHistoryExport, state => ({ ...state, error: null })),
    on(getConsumerRechargeHistoryExportSuccess, (state, { getConsumersRechargeHistoryExportResponse }) => ({ ...state, getConsumersRechargeHistoryExportResponse })),
    on(getConsumerRechargeHistoryExportFail, (state, { getConsumersRechargeHistoryExportError }) => ({ ...state, getConsumersRechargeHistoryExportError })),


    on(getConsumptionHistoryExport, state => ({ ...state, error: null })),
    on(getConsumptionHistoryExportSuccess, (state, { getconsumptionHistoryExportResponse }) => ({ ...state, getconsumptionHistoryExportResponse })),
    on(getConsumptionHistoryExportFail, (state, { getconsumptionHistoryExportError }) => ({ ...state, getconsumptionHistoryExportError })),



    on(getmeterDeatails, state => ({ ...state, error: null })),
    on(getmeterDeatailsSuccess, (state, { getmeterDeatailsResponse }) => ({ ...state, getmeterDeatailsResponse })),
    on(getmeterDeatailsFail, (state, { getmeterDeatailsError }) => ({ ...state, getmeterDeatailsError })),


    on(getmeterControls, state => ({ ...state, error: null })),
    on(getmeterControlsSuccess, (state, { getmeterControlsResponse }) => ({ ...state, getmeterControlsResponse })),
    on(getmeterControlsFail, (state, { getmeterControlsError }) => ({ ...state, getmeterControlsError })),


    on(deleteConsumerSuccess, (state, { deleteConsumerResponse }) => ({ ...state, deleteConsumerResponse })),

    on(getDueBills, state => ({ ...state, error: null })),
    on(getDueBillsSuccess, (state, { getDueBillsResponse }) => ({ ...state, getDueBillsResponse })),
    on(getDueBillsFail, (state, { getDueBillsError }) => ({ ...state, getDueBillsError })),

    on(getBillHistory, state => ({ ...state, error: null })),
    on(getBillHistorySuccess, (state, { getBillHistoryResponse }) => ({ ...state, getBillHistoryResponse })),
    on(getBillHistoryFail, (state, { getBillHistoryError }) => ({ ...state, getBillHistoryError })),

    on(getBillHistoryExport, state => ({ ...state, error: null })),
    on(getBillHistoryExportSuccess, (state, { getBillHistoryExportResponse }) => ({ ...state, getBillHistoryExportResponse })),
    on(getBillHistoryExportFail, (state, { getBillHistoryExportError }) => ({ ...state, getBillHistoryExportError })),


    on(getConsumerMeteOnOffExport, state => ({ ...state, error: null })),
    on(getConsumerMeterOnOffExportSuccess, (state, { getConsumersMeterOnOffExportResponse }) => ({ ...state, getConsumersMeterOnOffExportResponse })),
    on(getConsumerMeterOnOffExportrFail, (state, { getConsumersMeterOnOffExportError }) => ({ ...state, getConsumersMeterOnOffExportError })),


    on(getsettlement, state => ({ ...state, error: null })),
    on(getsettlementSuccess, (state, { getsettlementResponse }) => ({ ...state, getsettlementResponse })),
    on(getsettlementFail, (state, { getsettlementError }) => ({ ...state, getsettlementError })),



    on(retrysettlement, state => ({ ...state, error: null })),
    on(retrysettlementSuccess, (state, { retrysettlementResponse }) => ({ ...state, retrysettlementResponse })),
    on(retrysettlementFail, (state, { retrysettlementError }) => ({ ...state, retrysettlementError })),



    on(getsettlementExport, state => ({ ...state, error: null })),
    on(getsettlementExportSuccess, (state, { getsettlementExportResponse }) => ({ ...state, getsettlementExportResponse })),
    on(getsettlementExportFail, (state, { getsettlementExportError }) => ({ ...state, getsettlementExportError })),

    on(resetsettlementExportState, (state) => ({
        ...state,
        getsettlementExportResponse: null
    })),



    on(getBilldeatils, state => ({ ...state, error: null })),
    on(getBilldeatilsSuccess, (state, { getBilldeatilsResponse }) => ({ ...state, getBilldeatilsResponse })),
    on(getBilldeatilsFail, (state, { getBilldeatilsError }) => ({ ...state, getBilldeatilsError })),


    on(updateConsumerMeterDetails, state => ({ ...state, error: null })),
    on(updateConsumerMeterDetailstSuccess, (state, { updateConsumerMeterDetailssuccessResponse }) => ({ ...state, updateConsumerMeterDetailssuccessResponse })),
    on(updateConsumerMeterDetailsFail, (state, { updateConsumerMeterDetailssuccessError }) => ({ ...state, updateConsumerMeterDetailssuccessError })),


    on(getMeterOnOffControls, state => ({ ...state, error: null })),
    on(getMeterOnOffControlsSuccess, (state, { getMeterOnOffControlsResponse }) => ({ ...state, getMeterOnOffControlsResponse })),
    on(getMeterOnOffControlsFail, (state, { getMeterOnOffControlsError }) => ({ ...state, getMeterOnOffControlsError })),


    on(resetupdateConsumerMeterDetails, (state) => ({
        ...state,
        updateConsumerMeterDetailssuccessResponse: null
    })),
    on(resetupdateConsumerMeterDetails, (state) => ({
        ...state,
        getBilldeatilsResponse: null
    })),


    //resegetBilldeatils

    on(resetState, (state) => ({
        ...state,
        getConsumersExportResponse: null
    })),
    on(getConsumersresetState, (state) => ({
        ...state,
        getConsumersResponse: null
    })),

    on(resetBillHistoryState, (state) => ({
        ...state,
        getBillHistoryExportResponse: null
    })),

    on(resetBillRechargeHistoryState, (state) => ({
        ...state,
        getConsumersRechargeHistoryExportResponse: null
    })),


    on(resetBillmeterControlsState, (state) => ({
        ...state,
        getConsumersMeterExportResponse: null
    })),

    on(resetBillonOffControlsState, (state) => ({
        ...state,
        getConsumersMeterOnOffExportResponse: null
    })),

    on(resetConsumptionHistoryState, (state) => ({
        ...state,
        getconsumptionHistoryExportResponse: null
    })),
    on(resetrechargeHistoryState, (state) => ({
        ...state,
        getConsumersRechargeHistoryExportResponse: null,
        // Resetting this specific property
    }))



);



