// masters.effects.ts
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { ProjectsService } from '../../core/services/projects.services';
import {
  GetProjects,
  createProject,
  getProjectsFailure,
  getProjectsSuccess,
  createProjectSuccess,
  createProjectFailure,
  updateProjectSuccess,
  updateProject,
  updateProjectFailure,
  deleteProject,
  deleteProjectFailure,
  deleteProjectSuccess,
  GetallProjects,
  getAllProjectsSuccess,
  getAllProjectsFailure,
  getProject,
  getProjectFailure,
  getProjectDataSuccess,
} from './projects.actions';
@Injectable()
export class projectModuleEffects {
  ServiceError = Validations.ServiceError;
  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProject),
      exhaustMap((projectData) => {
        console.log(projectData);
        this.loaderService.setLoading(true);
        return this.projectService.createProjects(projectData.projectData).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              setTimeout(() => {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
              }, 100);
              this.router.navigate(['/projects']);
              return createProjectSuccess({ createProjectResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return createProjectFailure({ createProjectError: response });
            }
          }),
          catchError((error) => {
            console.log(error);

            this.sharedService.showMessageDialog(
              error.error.message,
              '',
              SweetAlertIcon.ERROR
            );
            return of(createProjectFailure({ createProjectError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );



  getProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProject),
      exhaustMap((projectData, id) => {
        console.log(projectData, '-----', id);
        this.loaderService.setLoading(true);
        return this.projectService
          .gerProjectBasedOnProjectId( projectData.projectId)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                // this.router.navigate(['/projects']);
              
                return getProjectDataSuccess({
                  getProjectDataSuccessResponse: response,
                });
                // return Getmaster({pageIndex:1,pageSize:10,searchTerm:''})
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return getProjectFailure({ getProjectFailure: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.loaderService.setLoading(false);
              this.sharedService.showMessageDialog(
                error.error.message,
                '',
                SweetAlertIcon.ERROR
              );
              return of(getProjectFailure({ getProjectFailure: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );



  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProject),
      exhaustMap((projectData, id) => {
        console.log(projectData, '-----', id);
        this.loaderService.setLoading(true);
        return this.projectService
          .updateProjects(projectData.projectData, projectData.id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                // this.router.navigate(['/projects']);
                setTimeout(() => {
                  this.sharedService.showMessageDialog(
                    'Success',
                    response.message,
                    SweetAlertIcon.SUCCESS
                  );
                }, 100);

                return updateProjectSuccess({
                  updateProjectResponse: response,
                });
                // return Getmaster({pageIndex:1,pageSize:10,searchTerm:''})
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateProjectFailure({ updateProjectError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.loaderService.setLoading(false);
              this.sharedService.showMessageDialog(
                error.error.message,
                '',
                SweetAlertIcon.ERROR
              );
              return of(updateProjectFailure({ updateProjectError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProject),
      exhaustMap((projectData, id) => {
        console.log(projectData, '-----', id);
        this.loaderService.setLoading(true);
        return this.projectService.deleteProjects(projectData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteProjectSuccess({ deleteProjectResponse: response });
              // return GetProjects({pageIndex:1,pageSize:10,searchTerm:''})
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteProjectFailure({ deleteProjectError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              '',
              SweetAlertIcon.ERROR
            );
            return of(deleteProjectFailure({ deleteProjectError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  GetProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetProjects),
      exhaustMap(
        ({
          pageIndex,
          pageSize,
          searchTerm,
          Region,
          FromDate,
          ToDate,
          filter,
          orderBy,
        }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.projectService
            .getProjects(
              pageIndex,
              pageSize,
              searchTerm,
              Region,
              FromDate,
              ToDate,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getProjectsSuccess({
                    getProjectsResponse: response.data,
                  });
                } else {
                  return getProjectsFailure({ getProjectsError: response });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.loaderService.setLoading(false);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  '',
                  SweetAlertIcon.ERROR
                );
                return of(getProjectsFailure({ getProjectsError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  GetallProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetallProjects),
      exhaustMap(
        ({
          pageIndex,
          pageSize,
          searchTerm,
          Region,
          FromDate,
          ToDate,
          filter,
          orderBy,
          criteria,
        }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.projectService
            .getProjects(
              pageIndex,
              pageSize,
              searchTerm,
              Region,
              FromDate,
              ToDate,
              filter,
              orderBy,
              criteria
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllProjectsSuccess({
                    getAllProjectsResponse: response.data,
                  });
                } else {
                  return getAllProjectsFailure({
                    getAllProjectsError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.loaderService.setLoading(false);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  '',
                  SweetAlertIcon.ERROR
                );
                return of(getProjectsFailure({ getProjectsError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private projectService: ProjectsService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
}
