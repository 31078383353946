import {
  GetAllmapping,
  unMappingUmd,
  Getmapping,
  getAllmappingFailure,
  getAllmappingSuccess,
  getmappingFailure,
  getmappingSuccess,
} from './mapping.actions';
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { unMappingUmdSuccess, unMappingUmdFailure } from './mapping.actions';
import { MappingService } from '../../core/services/mapping.services';
import {
  getListOfUnMappingUmdFailure,
  getListOfUnMappingUmdSuccess,
  getListOfUnMappingUmd,
} from './mapping.actions';
import {
  mappingUmdFailure,
  mappingUmdSuccess,
  MappingUmd,
} from './mapping.actions';
@Injectable()
export class MappingEffects {
  ServiceError = Validations.ServiceError;

  getmapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Getmapping),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.mappingervice
          .getmappings(pageIndex, pageSize, searchTerm, id, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getmappingSuccess({
                  getmappingConfigResponse: response.data,
                });
              } else {
                return getmappingFailure({ getmappingError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getmappingFailure({ getmappingError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllmapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllmapping),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.mappingervice
          .getmappings(pageIndex, pageSize, searchTerm, id, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllmappingSuccess({
                  getAllmappingConfigResponse: response.data,
                });
              } else {
                return getAllmappingFailure({ getAllmappingError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getAllmappingFailure({ getAllmappingError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  unMappingUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unMappingUmd),
      exhaustMap(({ id, Umdid }) => {
        this.loaderService.setLoading(true);
        return this.mappingervice.unmappingStatus(id, Umdid).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return unMappingUmdSuccess({
                unMappingUmdSuccessResponse: response,
              });
            } else {
              return unMappingUmdFailure({
                unMappingUmdSuccessError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              unMappingUmdFailure({
                unMappingUmdSuccessError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  MappingUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MappingUmd),
      exhaustMap(({ id, Umdid }) => {
        this.loaderService.setLoading(true);
        return this.mappingervice.createmappings(id, Umdid).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return mappingUmdSuccess({
                mappingUmdSuccessResponse: response,
              });
            } else {
              return mappingUmdFailure({
                mappingUmdSuccessError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              mappingUmdFailure({
                mappingUmdSuccessError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getListOfUnMappingUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListOfUnMappingUmd),
      exhaustMap(({ id }) => {
        this.loaderService.setLoading(true);
        return this.mappingervice.getListOfUnMappingUmdUrl(id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getListOfUnMappingUmdSuccess({
                getListOfUnMappingUmdResponse: response.data,
              });
            } else {
              return getListOfUnMappingUmdFailure({
                getListOfUnMappingUmdError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getListOfUnMappingUmdFailure({
                getListOfUnMappingUmdError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private mappingervice: MappingService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
}
